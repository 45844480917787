import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import sideNavImg from "Assets/Company/sideNavImg.webp";
import AvatarImage from "Assets/Images/Avatar.webp";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BugsIcon from "Assets/Images/bugs.webp";
import MrButton from "Components/MrButton";
import UploadIcon from "Assets/Images/UploadIcon.webp";
import ModalPopUp from "Components/ModalPopUp";
// import MrButton from "Components/MrButton";
import { FileUploader } from "react-drag-drop-files";
import successGif from "Assets/Images/successGif.gif";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { salesmanSideNavMenus } from "DummyData";
import { useSelector } from "react-redux";

const fileTypes = ["JPG", "PNG", "GIF"];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SalesmanSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const xs = useMediaQuery("(max-Width: 600px)");

  const { allResellers } = useSelector(
    (state) => state.isSalesmanDadhbaordSlice
  );

  const [file, setFile] = useState(null);
  const [logoutpop, setLogoutPop] = useState(false);
  const [reportPop, setReportPop] = useState(false);
  const [responsePop, setResponsePop] = useState(false);
  const [resetUploader, setResetUploader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    issue: "",
  });

  const handleImg = (file) => {
    setFile(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(file, "file");

    setFormData({
      name: "",
      email: "",
      issue: "",
    });
    setFile(null);
    setResetUploader((prev) => !prev);
    setReportPop(false);
    setResponsePop(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          px: "1.56rem",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: "1.5rem",
          }}
        >
          <Box
            onClick={() => navigate("/salesman-dashboard")}
            sx={{
              height: "6.2rem",
              width: "6.2rem",
              cursor: "pointer",
            }}
          >
            <img
              src={allResellers?.is_company_logo || sideNavImg}
              alt="No Preview"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            maxHeight: "calc(100% - 12.6rem)",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {salesmanSideNavMenus &&
            salesmanSideNavMenus?.length > 0 &&
            salesmanSideNavMenus?.map((item, i) => (
              <NavLink
                key={i}
                to={item?.path}
                style={({ isActive }) => {
                  let backgroundColor = "#ffffff";
                  let color = isActive ? "#96429E" : "#606060";

                  if (
                    (window.location.href.includes("/country-plans") ||
                      window.location.href.includes("/regional-plans") ||
                      location.pathname === "/customerDetails" ||
                      location.pathname === "/checkout" ||
                      location.pathname === "/add-partner" ||
                      location.pathname === "/salesman-dashboard") &&
                    i === 0
                  ) {
                    backgroundColor = "#F7F4F8";
                    color = "#96429E";
                  } else if (
                    (location.pathname === "/all-agents" ||
                      location.pathname === "/agent-details") &&
                    i === 1
                  ) {
                    backgroundColor = "#F7F4F8";
                    color = "#96429E";
                  } else if (
                    location.pathname === "/salesman-analytics" &&
                    i === 2
                  ) {
                    backgroundColor = "#F7F4F8";
                    color = "#96429E";
                  } else if (location.pathname === "/sim-stocks" && i === 3) {
                    backgroundColor = "#F7F4F8";
                    color = "#96429E";
                  } else if (
                    (location.pathname === "/direct-sell" ||
                      location.pathname === "/d-kyc") &&
                    i === 4
                  ) {
                    backgroundColor = "#F7F4F8";
                    color = "#96429E";
                  } else if (location.pathname === "/mystock" && i === 5) {
                    backgroundColor = "#F7F4F8";
                    color = "#96429E";
                  }

                  return {
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    backgroundColor,
                    color,
                    width: "100%",
                    minHeight: "3.125rem",
                    borderRadius: "0.625rem",
                    overflow: "hidden",
                  };
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "0.5rem",
                    pl: "1rem",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={item?.icon}
                      alt="No Preview"
                      style={{
                        height: "1.2rem",
                        width: "1.2rem",
                        objectFit: "cover",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1rem", fontWeight: "400" }}
                  >
                    {item?.menuItem}
                  </Typography>
                </Box>
              </NavLink>
            ))}
        </Box>
        {xs ? (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              py: "0.5rem",
              justifyContent: "center",
              alignItems: "center",
              pl: "0.8rem",
            }}
            onClick={() => setReportPop(true)}
          >
            <Box
              sx={{
                height: "1.2rem",
                width: "1.2rem",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={BugsIcon}
                alt="No Preview"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  opacity: "0.5",
                }}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("Assets/Images/no-preview.jpeg");
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: "1rem", fontWeight: "400", color: "#606060" }}
              >
                Report a Problem
              </Typography>
            </Box>
          </Box>
        ) : null}

        <Box
          sx={{
            position: "absolute",
            bottom: "0rem",
            left: "0rem",
            right: "0rem",
            px: "0.5rem",
            width: "100%",
            backgroundColor: "white.main",
          }}
        >
          <Box
            sx={{
              height: "4.5rem",
              borderTop: "1.5px solid #e5e5e5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: "1rem",
              gap: "1rem",
            }}
          >
            <Box sx={{ display: "flex", gap: "0.75rem" }}>
              <img
                src={AvatarImage}
                alt="No Preview"
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  objectFit: "cover",
                }}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("Assets/Images/no-preview.jpeg");
                }}
              />
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {localStorage.getItem("salesman_name") || "Jhon Doe"}
                  {/* Vivek Malhotra */}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    color: "#606060",
                  }}
                >
                  {localStorage.getItem("salesman_emailid") ||
                    "jhondoe@gmail.com"}
                  {/* vm@prune.co.in */}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                aria-label="logout"
                onClick={() => setLogoutPop(true)}
              >
                <LogoutIcon sx={{ color: "#606060" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* log out pop up */}
      <Dialog
        open={logoutpop}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        onClose={() => setLogoutPop(false)}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: ".75rem",
            overflow: "hidden",
            py: ".75rem",
            px: "1rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "9rem",
            width: "25rem",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            sx={{ fontSize: "1.5rem", color: "primary.main" }}
          >
            Logout
          </Typography>
          <DialogTitle sx={{ textAlign: "center" }}>
            {"Are you sure want to logout ?"}
          </DialogTitle>
          <DialogActions sx={{ mt: "-.5rem" }}>
            <MrButton
              onClick={() => {
                setLogoutPop(false);
              }}
              width={"6rem"}
              variant={"outlined"}
            >
              Cancel
            </MrButton>
            <MrButton
              variant={"contained"}
              width={"6rem"}
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              Yes
            </MrButton>
          </DialogActions>
        </Box>
      </Dialog>

      {/* report file pop up  */}
      <ModalPopUp
        open={reportPop}
        onClose={() => setReportPop(false)}
        title="Report an Issue"
        width={"28rem"}
        height={"90%"}
        showHeader={true}
        justifyContent={"start"}
      >
        <Box
          sx={{
            p: "1rem",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <TextField
                onChange={handleChange}
                fullWidth
                required
                type="text"
                id="outlined-required"
                label="Name"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    width: "100%",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
                name="name"
                value={formData.name}
              />
              <TextField
                onChange={handleChange}
                fullWidth
                required
                type="email"
                id="outlined-required"
                label="Email ID"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    width: "100%",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
                name="email"
                value={formData.email}
              />

              <TextField
                onChange={handleChange}
                fullWidth
                required
                type="text"
                id="outlined-required"
                label="Your Issue"
                placeholder="Please explain Problem you are facing here"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    width: "100%",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
                name="issue"
                multiline
                minRows={3}
                maxRows={3}
                value={formData.issue}
              />

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Typography variant="body1">Add an Image</Typography>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      height: "3rem",
                      width: "3rem",
                      cursor: "pointer",
                      position: "absolute",
                      top: "25%",
                      left: "50%",
                      transform: "translate(-50%,0%)",
                    }}
                  >
                    <img
                      src={UploadIcon}
                      alt="No Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                  <FileUploader
                    key={resetUploader}
                    handleChange={handleImg}
                    name="file"
                    types={fileTypes}
                    value={file}
                    height={"7rem"}
                    label="Drag & drop file here or click to browse"
                    classes="drope_zone"
                    dropMessageStyle={{
                      backgroundColor: "#f589ff",
                    }}
                    required
                  />
                </Box>
                {file ? (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "0.7rem", color: "#666666" }}
                  >
                    {file.name}
                  </Typography>
                ) : null}
              </Box>
              <MrButton type={"submit"} variant={"contained"} width={"100%"}>
                Submit
              </MrButton>
            </Box>
          </form>
        </Box>
      </ModalPopUp>

      {/* response successes pop up  */}
      <Dialog
        open={responsePop}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setResponsePop(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: ".75rem",
            overflow: "hidden",
            py: "1.5rem",
            px: { xs: "0rem", sm: "2rem", md: "2rem" },
            // border: "1px solid red",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "25rem",
            alignItems: "center",
            // border: "1px solid blue",
          }}
        >
          <Box sx={{ height: "7.5rem", width: "7.5rem" }}>
            <img
              src={successGif}
              alt="No Preview"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>
          <Typography
            variant="body1"
            sx={{
              mb: "0.5rem",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "1.25rem",
              color: "black.main",
            }}
          >
            Report Submitted Succesfully
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography variant="body1" sx={{ fontSize: "0.875rem" }}>
              You can view status of your tickets in my tickets section
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                border: "1px solid #DADADA",
                borderRadius: "0.625rem",
                px: "1rem",
                py: "0.5rem",
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "400", color: "#606060" }}
                >
                  Ticket ID
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  7847387468763
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "400", color: "#606060" }}
                >
                  Date & Time
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  23-10-2022 at 14:00
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <DialogActions>
          <IconButton
            onClick={() => setResponsePop(false)}
            sx={{
              position: "absolute",
              top: ".5rem",
              right: ".5rem",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

SalesmanSidebar.propTypes = {
  handleCloseNav: PropTypes.func,
};

export default SalesmanSidebar;
