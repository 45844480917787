import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllSalesmanStock = createAsyncThunk(
  "getAllSalesmanStock",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

// export const getSingleOrder = createAsyncThunk(
//   "getSingleOrder",
//   async ({ url }) => {
//     try {
//       const response = await axios.get(url, {
//         headers: {
//           authorization: `Token ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//           // authorization: `Basic ${basicAuth}`,
//           // "Access-Control-Allow-Origin": "*",
//         },
//       });

//       return response?.data;
//     } catch (error) {
//       console.log(error);
//       return error?.response?.data;
//     }
//   }
// );

const salesmanStockSlice = createSlice({
  name: "salesmanStockSlice",
  initialState: {
    loading: false,
    allStock: null,
    totalPage: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllSalesmanStock.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSalesmanStock.fulfilled, (state, action) => {
        state.loading = false;
        state.allStock = action?.payload;
        state.totalPage = action?.payload?.total_pages;
      })
      .addCase(getAllSalesmanStock.rejected, (state) => {
        state.loading = false;
      });
    //   .addCase(getSingleOrder.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(getSingleOrder.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.singleOrder = action?.payload;
    //   })
    //   .addCase(getSingleOrder.rejected, (state) => {
    //     state.loading = false;
    //   });
  },
});

export default salesmanStockSlice.reducer;
