import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllReseller = createAsyncThunk(
  "getAllReseller",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getSingleResellerDetail = createAsyncThunk(
  "getSingleResellerDetail",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${localStorage.getItem("reseller_token")}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const salesmanDadhbaordSlice = createSlice({
  name: "salesmanDadhbaordSlice",
  initialState: {
    loading: false,
    allResellers: null,
    totalPage: null,
    singleReseller: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.allResellers = action?.payload;
        state.totalPage = action?.payload?.total_pages;
      })
      .addCase(getAllReseller.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleResellerDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleResellerDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.singleReseller = action?.payload;        
      })
      .addCase(getSingleResellerDetail.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default salesmanDadhbaordSlice.reducer;
