import React from "react";
import "./termsAndCondition.css";
import { Box } from "@mui/material";

const TermAndCondition = () => {
  return (
    <>
      <Box sx={{ px: { xs: ".5rem", sm: "4rem", md: "4rem" } }}>
        <div className="container">
          <div className="padding-custom-1">
            <h3
              className="defined-center-custom"
              style={{ textAlign: "center", margin: "32px" }}
            >
              PRUNE PRIVACY POLICY
            </h3>
          </div>

          <p>
            When you use Prune services, you trust us with your information.
            This is an electronic record in the form of an electronic contract
            formed under the Information Technology Act, 2000, and the rules
            made thereunder and the amended provisions on electronic
            documents/records in various statutes as amended by the Information
            Technology Act, 2000. There is no physical, electronic, or digital
            signature required for this privacy policy.
          </p>

          <p>
            This Privacy Policy is a legally binding document between you and
            Prune. The terms of this Privacy Policy will be effective upon your
            acceptance of the same. This Privacy Policy specifies the terms
            related to the management and handling of personal information of
            users (hereinafter referred to as &quot;the Users&quot;) using the
            services of the Prune app accessible through the Mobile application
            (hereinafter &quot;Prune Application&quot;), phone expense
            management services for smartphone (hereinafter &quot;Service&quot;)
            of Prune. We understand that you entrust us with certain Information
            to help us provide various Services.
            <strong>
              You expect and deserve our commitment to treat your information
              with respect and in compliance with the conditions of this Privacy
              Policy (&quot;Privacy Policy&quot;) in exchange for your trust.
            </strong>
          </p>

          <p>
            The Privacy Policy describes the policies and procedures followed by
            Prune concerning the collection, use, and disclosure of your
            Information and other data that we gather when you register for
            using our Prune Application and Services by creating a user account
            (&quot;Account&quot;), interact with other Users and third-party
            websites and services. This document is published and shall be
            construed by the provisions of the Information Technology
            (reasonable security practices and procedures and sensitive personal
            data or information) Rules, 2011 under the Information Technology
            Act, 2000; which require publishing of the Privacy Policy for
            collection, use, storage and transfer of sensitive personal data or
            information. Please read this Privacy Policy carefully. By using the
            website, you indicate that you understand, agree, and consent to
            this Privacy Policy. Please do not use our application or website if
            you do not agree to the terms of this Privacy Policy. You hereby
            provide your unconditional consent or agreements as provided under
            section 43a and section 72a of the Information Technology Act, 2000.
          </p>

          <p>
            Prune complies with all rules and regulations governing the
            protection of personal information that apply to information and
            communication service providers. The following is the Personal
            Information Policy for services:
          </p>

          <p>
            This Privacy Policy is intended to inform you about the kind of
            information we collect, why we collect it, and how we use it. This
            is important; we hope you will take time to read it carefully.
          </p>

          <div className="padding-custom-1">
            <h3 className="defined-center-custom">TYPES OF DATA COLLECTED</h3>
          </div>

          <p>
            This Application gathers the following categories of Personal Data,
            either directly or through third parties: Cookie, Phone and Data
            Usage, Phone number, Email address, and Geographic position. Prune
            also captures USSD from the operator to calculate the amount data
            used or the value of the last Mobile bill to help save money.
          </p>

          <p>
            Other Personal Data collected may be described in other sections of
            this privacy policy or by dedicated explanation text contextually
            with the Data collection.
          </p>

          <p>
            Personal Data may be freely submitted by the User or automatically
            collected when this Application is used.
          </p>

          <p>
            Unless specified otherwise, any use of Cookies – or other tracking
            techniques – by this Application or the owners of third-party
            services used by this Application is solely to identify Users and
            remember their preferences to provide the service requested by the
            User.
          </p>

          <p>
            The Personal Information will not be used for purposes other than
            listed below, and the prior approval of the Users would be required
            in case of change in the purposes given below. Personal Information
            for this Privacy Policy shall include, but not be limited to:
          </p>

          <p>
            Helping you Find Best Plan, Devices &amp; Network: Name, Data usage
            balance and Phone call balance of the User&apos;s mobile device,
            Content of messages (to extract phone usage balance information),
            Phone number, Email address, Location, Contact List, Logs, IP
            address, Device ID, and Country of Origin.
          </p>

          <p>
            Improved functionality and service: Data use balance on the
            User&apos;s mobile device, Phone call balance on the User&apos;s
            mobile device, Access Log, Purchase records for products and
            services, Type and version of the internet browser and the operating
            system, Type and manufacturer of the mobile phone, Name and Version
            of system and application software; Other information collected at
            the time of installation: Installed applications on User&apos;s
            device; Call logs (Date, callers and receivers information); SMS
            logs (Date, callers and receivers information); photo logs to find
            patterns of date and time of creation and contact list.
          </p>

          <strong>
            <p>
              Personal Information is collected by Prune through the methods
              listed below:
            </p>
          </strong>

          <p>
            1. Prune&apos;s website enrolment, mobile app, written form, fax,
            telephone, e-mail, electronic board, promotional offer
            participation, and customer support.
          </p>

          <p>2. Voluntary provision from the Users through service.</p>

          <p>
            We may receive Personal information about you from third parties,
            such as social media services, commercially available sources, and
            business partners. If you access the Website through a social media
            service or connect the service on the Website to a social media
            service, the information we collect may include your user&apos;s
            name associated with that social media service, any information or
            content the social media service has the right to share with us,
            such as your profile picture, email address or friends list, and any
            information you have made public in connection with that social
            media service. When you access the Website through social media
            services or when you connect any Website to social media services,
            you are authorizing Prune to collect, store, use, and retain such
            information and content by this Privacy Policy.
          </p>

          <p>
            Failure to provide certain Personal Data may make it impossible for
            this Application to provide its services.
          </p>

          <p>
            <a
              href="https://prune.co.in/privacy-policy/data-deletion"
              className="btn btn-danger"
              style={{
                height: "50px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "150px",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Delete My Data
            </a>
          </p>
        </div>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            MODE AND PLACE OF PROCESSING THE DATA
          </h3>
        </div>
        <p>&nbsp;</p>

        <h4>
          <p>Methods Of Processing</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          The Data Controller properly processes the Data of Users and shall
          take appropriate security measures to prevent unauthorized access,
          disclosure, modification, or unauthorized destruction of the Data. The
          Data processing is carried out using computers and/or IT-enabled
          tools, following organizational procedures and modes strictly related
          to the purposes indicated. In some situations, the Data may be
          available to certain categories of personnel in charge, involved with
          the site&apos;s operation, in addition to the Data Controller
          (administration, sales, marketing, legal, system administration) or
          external parties (such as third-party technical service providers,
          mail carriers, hosting providers, IT companies, communications
          agencies) appointed, if necessary, as Data Processors by the Owner.
          The Data Controller can provide you with an updated list of these
          parties at any time.
        </p>

        <p>&nbsp;</p>

        <p>
          To improve the Company&apos;s services, we also require log
          information so that we may determine how our services are used and
          provide better services with updates based on client preferences.
          Prune also uses your correspondence information, to determine your
          preference and your feedback to improve our services. Prune uses the
          information to complete your registration process and other services
          requested by you. Also, the information is used to provide you with a
          detail account of your account and the statement of expenditure and
          also help us detect and protect users and ourselves against errors,
          fraud, or other criminal activity. The data that we collect using the
          cookies, assist us in understanding the user trends and to improve
          according to the customer preferences. Prune at times collects the
          information in the form of profiles, in order to customise the
          services according to the usage. It is important to note that the
          information gathered by Prune will become the company&apos;s property
          and can be used at its discretion without recompense to you.
        </p>

        <p>
          <br />
          &nbsp;
        </p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">Place</h3>
        </div>
        <p>&nbsp;</p>

        <p>
          The Data is processed at the Data Controller&apos;s offices and any
          other locations where the parties engaged in the processing are.
          Please contact the Data Controller for more information.
        </p>

        <p>&nbsp;</p>

        <p>
          You hereby unconditionally agree and permit that except as otherwise
          provided under this Privacy Policy, Prune will disclose your Personal
          Information to third parties only in the following limited
          circumstances:
        </p>

        <p>&nbsp;</p>

        <p>
          1.&nbsp;We may transfer, share, disclose, or part with all or any of
          Your Information, within and outside of the Country i.e. India to
          various third-party service providers/partners/banks and financial
          institutions for one or more of the Purposes or as may be required by
          applicable law. In such case, we will contractually oblige the
          receiving parties of the Information to ensure the same level of data
          protection that is adhered to by Prune under applicable law.
        </p>

        <p>&nbsp;</p>

        <p>
          2.&nbsp;To trustworthy partners under confidentiality agreements who
          work on our behalf or with us. These entities may use your Personal
          Information for performing marketing services, delivering goods,
          administering promotions, analyzing data and usage of the Service
          through the Prune Application, processing credit/debit card payments,
          operating the Service, or providing support and maintenance services
          for the same, or providing customer service.
        </p>

        <p>&nbsp;</p>

        <p>
          3.&nbsp;You acknowledge and agree that We may utilize such third
          parties, such as a credit/debit card processing business, a payment
          gateway, pre-paid cards, and so on. to enable You to make payments for
          availing Services on the application. When You sign up for these
          Services, you may have the ability to save Your card details for
          future reference and faster future payments. In such cases, we may
          share Your relevant Personal Information, such as your name, address,
          and email address, with third parties as necessary for them to perform
          such services. Payments or authorizations are processed by the rules,
          terms, and conditions of these third parties, and we are not
          responsible or accountable to You or any third party for any delay or
          failure in payment processing on their end. We may also share
          information with government authorities or courts to investigate,
          prevent, or take action regarding illegal activities, suspected fraud,
          situations involving potential threats to the physical safety of any
          person, or violations of various terms and conditions or our policies,
          per applicable laws and directives.
        </p>

        <p>&nbsp;</p>

        <p>
          4.&nbsp;when we have your permission to distribute the information in
          any other way.
        </p>

        <p>&nbsp;</p>

        <h4>
          <p>Retention time</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          The Data is kept for the time necessary to provide the service
          requested by the User, or stated by the purposes outlined in this
          document, and the User can always request that the Data Controller
          suspend or remove the data.
        </p>

        <p>&nbsp;</p>
        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            THE USE OF THE COLLECTED DATA
          </h3>
        </div>

        <p>&nbsp;</p>

        <p>
          The Data concerning the User is collected to allow the Owner to
          provide its services, as well as for the following purposes:
          Analytics, Location-based interactions, Contacting the User, Social
          features, Hosting and backend infrastructure, Registration and
          authentication, and Managing support and contact requests.
        </p>

        <p>&nbsp;</p>

        <h4>
          <p>
            The Personal Data used for each purpose is outlined in the specific
            sections of this document.
          </p>
        </h4>
        <p>&nbsp;</p>
        <strong>
          <p>
            Please note that by visiting https://prune.co.in or accessing and/or
            availing products and services through the Platform you are
            accepting the practices described in this Privacy Policy including
            but not limited to your consent for collecting, using, sharing, and
            disclosing your information as per this Privacy Policy.
          </p>
        </strong>

        <table>
          <tbody>
            <tr>
              <td>Contact Information</td>
              <td>
                Depending on the Service opted by you, we may collect contact
                information to manage relationship with you including contacting
                your references submitted by you (if we are unable to reach you)
                and communicating with you about our products and services. For
                example - to contact you as a survey respondent, to notify you
                if you win any contest; and to send you promotional materials
                from its contest sponsors or advertisers.
              </td>
            </tr>
            <tr>
              <td>Personal Information</td>
              <td>
                Your full name, a government-issued identification, email
                address, mailing address, photograph, birthdate, etc when you
                apply, use, or sign up for Services.
              </td>
            </tr>
            <tr>
              <td>Camera</td>
              <td>
                For a better user experience in submitting documents and for a
                streamlined onboarding process.
              </td>
            </tr>
            <tr>
              <td>Customer Information</td>
              <td>
                Information you collect from your customers, including email
                addresses, phone numbers, payment information, or other
                information.
              </td>
            </tr>
            <tr>
              <td>Device Information</td>
              <td>
                Information about your device, including your hardware model,
                operating system and version, device name, unique device
                identifier, mobile network information, and information about
                the device&apos;s interaction with our Services.
              </td>
            </tr>
            <tr>
              <td>Demographic Information</td>
              <td>
                Location and geolocation information may be collected from your
                device. If you do not consent to the collection of this
                information, certain Services will not function properly, and
                you will not be able to use those Services.
              </td>
            </tr>
            <tr>
              <td>Others</td>
              <td>
                As may be explicitly required & and consented to by you from
                time to time.
              </td>
            </tr>
          </tbody>
        </table>

        <p>DETAILED INFORMATION ON THE PROCESSING OF PERSONAL DATA</p>
        <p>&nbsp;</p>
        <div className="padding-custom-1">
          <h3 className="defined-center-custom">Analytics</h3>
        </div>

        <p>&nbsp;</p>

        <p>
          The services in this part allow the Owner to track and evaluate their
          mobile consumption, as well as analyze usage patterns and recommend
          appropriate Mobile Recharge/Bill plans, Devices, and Networks to help
          them save money.
        </p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>
        <h4>
          <p>Hosting and backend infrastructure</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          These services are used to hold data and files that enable this
          Application to run and be disseminated, as well as to provide a
          ready-made infrastructure to execute specific features or parts of it.
          Some of these services use geographically spread servers, making it
          impossible to pinpoint the precise location of the Personal Data.
        </p>

        <p>&nbsp;</p>
        <h4>
          <p>Prune</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          Prune is a Mobile Analytics and Suggestion Engine supplied by Prune
          Digital Consumer Solutions&nbsp;Pvt.&nbsp;Ltd. (a company constituted
          under the Companies Act, 2013 with its registered office at C-146, DLF
          Capital Greens, Shivaji Marg, New Delhi – 110015 and headquartered at
          B-6, Sector-4, Noida – 201301 (hence referred to as &quot;Prune,&quot;
          &quot;we,&quot; &quot;our,&quot; or &quot;us&quot;). &quot;). Prune
          utilizes the Data collected to track and examine the use of this
          Application, to prepare reports on its activities, and to share them
          with Users of the application as an analysis.
        </p>

        <p>Personal Data collected.</p>

        <p>&nbsp;</p>

        <p>
          Various categories of personal data are gathered, as indicated in the
          service&apos;s privacy policy.
        </p>

        <p>&nbsp;</p>
        <h3>
          <p>Contacting the User</p>
        </h3>

        <p>&nbsp;</p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            PHONE CONTACT (THIS APPLICATION)
          </h3>
        </div>

        <p>&nbsp;</p>

        <p>
          Users who provided their phone numbers might be contacted for
          commercial or promotional purposes related to this Application, as
          well as for fulfilling support requests.
        </p>

        <p>&nbsp;</p>

        <p>Personal Data collected: Phone number.</p>

        <p>&nbsp;</p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            MAILING LIST OR NEWSLETTER (THIS APPLICATION)
          </h3>
        </div>
        <p>&nbsp;</p>

        <p>
          The User&apos;s email address will be added to the contact list of
          persons who may receive email messages containing commercial or
          promotional information about this Application if they sign up for the
          mailing list or the newsletter. As a result of signing up for this
          Application or making a purchase, your email address may be added to
          this list.
        </p>

        <p>&nbsp;</p>

        <strong>
          <p>
            Email addresses and phone numbers were acquired as personal data.
          </p>
        </strong>

        <p>&nbsp;</p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            CONTACT FORM (THIS APPLICATION)
          </h3>
        </div>

        <p>&nbsp;</p>

        <p>
          By filling in the contact form with their Data, the User authorizes
          this Application to use these details to reply to requests for
          information, quotes, or any other kind of request as indicated by the
          form&apos;s header.
        </p>

        <p>&nbsp;</p>

        <p>Personal Data collected: Phone number.</p>

        <p>&nbsp;</p>

        <h4>
          <p>Location-based interactions</p>
        </h4>

        <p>&nbsp;</p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            NON-CONTINUOUS GEOLOCATION (THIS APPLICATION)
          </h3>
        </div>

        <p>&nbsp;</p>

        <p>
          This Application may collect, use, and share User location Data to
          provide location-based services.
        </p>

        <p>&nbsp;</p>

        <p>
          By default, most browsers and devices give mechanisms to disable this
          capability. This Application may track the User&apos;s location data
          if express authorization has been given.
        </p>

        <p>&nbsp;</p>

        <p>
          The User&apos;s geographic location is determined in a non-continuous
          manner, either at the User&apos;s request or when the User fails to
          indicate their present location in the appropriate form and instead
          allows the program to detect their location when they make a call.
        </p>

        <p>&nbsp;</p>

        <p>Personal Data collected: Geographic position.</p>

        <p>&nbsp;</p>
        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            FURTHER INFORMATION ABOUT PERSONAL DATA
          </h3>
        </div>

        <p>&nbsp;</p>

        <h4>
          <p>Push notifications</p>
        </h4>

        <p>&nbsp;</p>

        <p>This Application may send push notifications to the User.</p>

        <p>&nbsp;</p>

        <h4>
          <p>Unique device identification</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          This Application may monitor Users by keeping a unique device
          identifier for analytics or storing User preferences.
        </p>

        <p>&nbsp;</p>

        <h4>
          <p>COOKIE POLICY</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          Cookies are used in this application. Consult the{" "}
          <a style={{ textDecoration: "underline" }} href="/cookies/">
            Cookie Policy
          </a>{" "}
          for additional information and a thorough cookie notice. The
          User&apos;s device stores a small amount of data.
        </p>

        <p>&nbsp;</p>
        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            ADDITIONAL INFORMATION ABOUT DATA COLLECTION AND PROCESSING
          </h3>
        </div>

        <p>&nbsp;</p>

        <h4>
          <p>Legal action</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          The Data Controller may use the User&apos;s Personal Data for legal
          purposes in Court or the stages leading up to potential legal action
          originating from illegal use of this Application or connected
          services.
        </p>

        <p>&nbsp;</p>
        <strong>
          <p>
            The User acknowledges that the Data Controller may be forced to
            provide personal information in response to public authorities&apos;
            requests.
          </p>
        </strong>

        <p>&nbsp;</p>
        <h3>
          <p>Additional information about User&apos;s Personal Data</p>
        </h3>

        <p>&nbsp;</p>

        <p>
          This Application may provide the User with additional and contextual
          information concerning particular services or the collection and
          processing of Personal Data upon request, in addition to the
          information included in this privacy policy.
        </p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <h3>
          <p>Information not contained in this policy</p>
        </h3>
        <p>&nbsp;</p>

        <p>
          The Data Controller can be contacted at any time for more information
          on the collection or processing of Personal Data. Please read the
          contact information at the start of this document for more
          information.
        </p>

        <p>&nbsp;</p>
        <h4>
          <p>The rights of Users</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          Users have the right to know whether their Personal Data has been
          stored at any time and to consult the Data Controller to learn about
          their contents and origins, to verify their accuracy, or to request
          that they be supplemented, canceled, updated, or corrected, or that
          they be transformed into anonymous format, or to request that any data
          held in violation of the law be blocked, as well as to object to their
          treatment for any legitimate reasons. Requests should be directed to
          the Data Controller at the address listed above.
        </p>

        <p>&nbsp;</p>

        <p>
          <strong>&quot;Do Not Track&quot;</strong>&nbsp;requests are not
          supported by this application.
        </p>

        <p>&nbsp;</p>

        <p>
          Please check the privacy rules of any third-party services it utilizes
          to see if they honor &quot;Do Not Track&quot; requests.
        </p>

        <p>&nbsp;</p>

        <h4>
          <p>Changes to this Privacy Policy</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          The Data Controller has the right to modify this privacy policy at any
          time by posting a notice on this page. It is strongly advised that you
          revisit this page frequently, keeping in mind the date of the most
          recent revision given at the bottom. If a User objects to any of the
          Policy&apos;s changes, the User must stop using the Application and
          can ask the Data Controller to delete the Personal Data. Unless
          otherwise noted, the Data Controller&apos;s current privacy policy
          applies to all Personal Data it has about Users.
        </p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">
            DEFINITIONS AND LEGAL REFERENCES
          </h3>
        </div>

        <p>&nbsp;</p>
        <h4>
          <p>Personal Data (or Data)</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          Any information about a natural person, a legal person, an
          organization, or an association that is or can be identified, even
          indirectly, by reference to other information, including a Mobile
          Number number or numbers.
        </p>

        <p>&nbsp;</p>
        <h4>
          <p>Usage Data</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          Information collected automatically from this Application, which can
          include: Phone Usage, Data Usage, IP addresses or domain names of the
          computers used by the Users who use this Application, URI addresses
          (Uniform Resource Identifier), the time of the request, the method
          used to submit the request to the server, the size of the file
          received in response, the numerical code indicating the status of the
          request, the time of the request, the method used to submit the
          request to the server, the (successful outcome, error, etc.), the
          User&apos;s country of origin, browser and operating system features,
          various time details per visit (e.g., the time spent on each page
          within the Application), details about the path taken within the
          Application with special reference to the sequence of pages visited,
          and other parameters about the device operating system and/or the
          User&apos;s IT environment.
        </p>

        <p>&nbsp;</p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">User</h3>
        </div>

        <p>&nbsp;</p>

        <p>
          The person using this Application must be the same as or authorized by
          the Data Subject to whom the Personal Data pertains.
        </p>

        <p>&nbsp;</p>

        <div className="padding-custom-1">
          <h3 className="defined-center-custom">Data Subject</h3>
        </div>

        <p>&nbsp;</p>

        <p>
          The legal or natural person who is the subject of the Personal Data.
        </p>

        <p>&nbsp;</p>
        <h4>
          <p>Data Processor (or Data Supervisor)</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          The Data Controller has allowed any natural person, legal person,
          public administration, or other body, association, or organization to
          treat Personal Data by this privacy policy.
        </p>

        <p>&nbsp;</p>
        <h4>
          <p>Data Controller (or Owner)</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          The natural person, legal person, public administration, or any other
          body, association, or organization with the right to make decisions
          about the purposes, methods, and means of processing Personal Data,
          including the security measures relating to the operation and use of
          this Application, individually or jointly with another Data
          Controller. Unless otherwise stated, the Owner of this Application is
          the Data Controller.
        </p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <h4>
          <p>GRIEVANCE OFFICER</p>
        </h4>

        <p>&nbsp;</p>

        <p>
          In accordance with Information Technology Act, 2000 and rules made
          there under, in case of grievances you can contact:
        </p>

        <p>&nbsp;</p>

        <p>Rishi Raj, Prune Digital Consumer Solutions Pvt. Ltd.,</p>

        <p>&nbsp;</p>

        <strong>
          <p>Email: Contact@prune.co.in</p>
        </strong>

        <p>&nbsp;</p>

        <p>
          In the event you wish to make a complaint regarding any violation of
          the provisions of the Privacy Policy, you may send a written complaint
          to the Grievance Officer,&nbsp;
          <strong>who shall redress the complaint within one (1) month.</strong>
        </p>

        <p>&nbsp;</p>

        <h4>
          <p>Legal information</p>
        </h4>

        <p>&nbsp;</p>

        <p>This privacy policy relates solely to this Application.</p>

        <p>&nbsp;</p>
      </Box>
    </>
  );
};

export default TermAndCondition;
